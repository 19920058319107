import { PusherService } from './services/pusher.service';
import { AuthenticationService } from './services/authentication.service';
import { Component } from '@angular/core';
import { PwaService } from './services/pwa.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'comv-admin';
  authenticated: boolean = false;

  constructor(
    public auth: AuthenticationService,
    private pusher: PusherService,
    public pwa: PwaService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'pdf_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/pdf.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'xls_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/xls.svg')
    );

    this.auth.currentAuthUser.subscribe((user) => {
      this.authenticated = user && user.exists() ? true : false;
    });
  }

  public updateApp() {
    console.log('Updating app!');
    window.location.reload();
  }
}
