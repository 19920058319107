import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import * as _ from 'lodash';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AdmoCleaningRule } from 'src/app/models/AdmoCleaningRule';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'clean-rules-dialog',
  templateUrl: './clean-rules-dialog.component.html',
  styleUrls: ['./clean-rules-dialog.component.scss']
})
export class CleanRulesDialog implements OnInit {
  public ready = false;
  public form: UntypedFormGroup = null;
  public btn_save_disabled: boolean = false;
  public btn_save_loader: boolean = false;
  public rules_loader: boolean = false;
  public mode: string = null;

  public selectedCountry: number = null;
  public admo_cleaner = null;
  public clean_rules = [
    {
      operator: 'keep_before',
      label: this.translate.instant("ADMONITORINGS.CLEAN_RULES_DIALOG.KEEP_BEFORE"),
      value_key: 'separator'
    },
    {
      operator: 'keep_after',
      label: this.translate.instant("ADMONITORINGS.CLEAN_RULES_DIALOG.KEEP_AFTER"),
      value_key: 'separator'
    },
    {
      operator: 'remove',
      label: this.translate.instant("ADMONITORINGS.CLEAN_RULES_DIALOG.REMOVE"),
      value_key: 'value'
    },
    {
      operator: 'remove_last',
      label: this.translate.instant("ADMONITORINGS.CLEAN_RULES_DIALOG.REMOVE_LAST"),
      value_key: 'value'
    }
  ];
  public rulesList = [];

  protected server_errors = null;
  public server_error = null;
  public save_bt_clicked: boolean = false;
  public admoCleaningRule: AdmoCleaningRule;
  public countryReadOnly: boolean = false;
  public countryExtraParameters = {
    filters:
      [
        { column: 'isVisibleNBB', operator: '=', value: 1 },
      ]
  }

  constructor(
    public dialogRef: MatDialogRef<CleanRulesDialog>,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data, 
    private dataService: DataService,
    public dialog: MatDialog) { 
      this.form = new UntypedFormGroup({
        country_id: new UntypedFormControl('', []),
        new_rule_operator: new UntypedFormControl('', []),
        new_rule_value: new UntypedFormControl('', []),
      }, {
        updateOn: 'change'
      });
    }

  ngOnInit(): void {
    let proms = [];

    if(this.data.rule && this.data.rule.id) {
      proms.push(
        this.dataService.getAsPromise(`admin/admo-cleaning-rules/${this.data.rule.id}`).then(res => {
          if(res && res.data) {
            this.admoCleaningRule = res.data;
          }
        }).catch(err => {
          console.log(err);
        })
      )
    }

    Promise.all(proms).then(res => {
      if(this.admoCleaningRule) {
        this.mode = 'update';
        this.form.patchValue(this.admoCleaningRule);
        this.countryReadOnly = true;
      } else {
        this.mode = 'new';
      }
      this.ready = true;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setData() {
    let data = {
      country_id: this.selectedCountry,
      admo_cleaner: null
    }
    let admo_cleaner = {};
    this.rulesList.forEach((rule, index) => {
      let r =  {
        operator: rule.operator
      };

      switch(rule.operator) {
        case 'keep_before':
        case 'keep_after':
          r['separator'] = rule.value;
          break;
        case 'remove':
        case 'remove_last':
          r['value'] = rule.value;
          break;
        case 'replace':
          r['value_from'] = rule.value_from;
          r['value_to'] = rule.value_to;
          break;
      }
      admo_cleaner[index] = r;
    });
    data['data'] = JSON.stringify(admo_cleaner);
    return data;
  }

  onSave() {
    // this.reValidateForms();
    let data = this.setData();
    this.server_error = null;
    if(this.selectedCountry) {
      if(this.mode == 'update') {
        this.update(data);
      } else {
        this.create(data);
      }
    }
  }

  create(data) {
    return this.dataService.postAsPromise(`admin/admo-cleaning-rules`, data).then(res => {
      this.dialogRef.close('bt_save');
    });
  }

  update(data) {
    return this.dataService.putAsPromise(`admin/admo-cleaning-rules/${this.admoCleaningRule.id}`, data).then(res => {
      this.dialogRef.close('bt_save');
    });
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }

  reValidateForms() {
    this.form.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    for (let f in this.form.controls) {
      this.form.controls[f].markAsTouched();
      this.form.controls[f].updateValueAndValidity({ emitEvent: false });
    }
  }

  onCountrySelect(country) {
    this.admo_cleaner = null;
    this.mode = null;
    this.rulesList = [];

    if(country && country.id) {
      const country_id = country.id;
      this.selectedCountry = country_id;
      this.rules_loader = true;
      this.dataService.getAsPromise(`admin/admonitorings/admo-cleaner`, {params: {country_id: country_id}}).then(res => {
        if(res && res.data) {
          this.mode = 'update';
          this.admo_cleaner = res.data;
          this.admoCleaningRule = res.data;
          this.setRulesList(this.admo_cleaner);
        } else {
          this.mode = 'new';
        }
        this.rules_loader = false;
      })
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rulesList, event.previousIndex, event.currentIndex);
  }

  deleteRule(index) {
    this.rulesList.splice(index, 1);
  }

  addRule() {
    this.reValidateForms();
    if(this.form.valid) {
      const new_rule_operator = this.form.get('new_rule_operator').value;
      const new_rule_value = this.form.get('new_rule_value').value;
      const operator = _.find(this.clean_rules, {operator: new_rule_operator});
      if(operator) {
        const rule = {
          operator: new_rule_operator,
          value: this.form.get('new_rule_value').value,
        };
        const n = {
          operator: new_rule_operator,
          operator_label: this.setOperatorLabel(rule),
          value: new_rule_value
        };
  
        this.rulesList.push(n);
        // clean new rule fields
        this.form.get('new_rule_value').reset();
      }
    }
  }

  onRuleOperatorChange(event) {
    const rule_operator = event.value;
    switch(rule_operator) {
      case 'keep_before':
        break;
      case 'keep_after':
        break;
      case 'remove':
        break;
      case 'remove_last':
        break;
      default:
        break;
    }
  }

  setRulesList(admo_cleaner) {
    const cleaner = JSON.parse(admo_cleaner.data);
    for(let element in cleaner) {
      let e = cleaner[element];
      const o = _.find(this.clean_rules, {operator: e['operator']});
      let value_key = null;
      if(o) {
        value_key = o['value_key'];
      } else {
        value_key = 'value';
      }
      let rule = {
        operator: e['operator'],
        operator_label: this.setOperatorLabel(e),
        value: e[value_key]
      };
      if(e['operator'] == 'replace') {
          rule['value_from'] = e.value_from;
          rule['value_to'] = e.value_to;
      }
      this.rulesList.push(rule);
    }
  }

  setOperatorLabel(rule) {
    let label = null;
    const o = _.find(this.clean_rules, {operator: rule['operator']});
      
    let operator_label = null;
    if(o) {
      operator_label = o.label;
    } else {
      operator_label = rule['operator'];
    }
    switch(rule['operator']) {
      case 'keep_before':
      case 'keep_after':
        label = operator_label+' "'+(rule['separator'] || rule['value'])+'"';
        break;
      case 'replace':
        label = operator_label+' "'+rule['value_from']+' '+rule['value_to']+'"';
        break;
      case 'remove':
      case 'remove_last':
        label = operator_label+' "'+rule['value']+'"';
        break;
      default:
        break;
    }
    return label;
  }
}
