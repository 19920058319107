import { DataService } from 'src/app/services/data.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialog  {
  public type = 'file_download'
  
  constructor(
    public dialogRef: MatDialogRef<ExportDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dataService:DataService) {
      if(data && data.type) {
        this.type = data.type;
      }
      this.dataService.downloads++;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
 

}
