export class Segment {
    id: number = null;
    name : string = null;
    card_count: number = null;
    updated_at: string = null;
    created_at: string = null;

    constructor( id? : number|Object) {
        if (typeof(id)=='object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                this[key] = data[key];
                
            }
        }
    }

};