import {Component, Input, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
@Input() item = null;
  @Input() items = [];
  @ViewChild('childMenu', {static: true}) public childMenu: any;

  @Output() itemEvent: EventEmitter<any> = new EventEmitter()

  constructor(public router: Router) {
  }

  ngOnInit() {
  }

  onItemClick(item, event) {
    if(item.type && item.type == 'checkbox') {
      event.stopPropagation();
      return;
    }
    if(item.callback) {
      item.callback()
    } else if(item.event) {
      this.itemEvent.emit(item.event);
    } else if(item.click) {
      item.click();
    }
  }

  onItemEvent(event) {
    this.itemEvent.emit(event);
  }

  onCheckboxItemChange(item, event) {
    this.itemEvent.emit({event: item.event, checkboxFieldName: item.checkboxFieldName, checkboxChangeEvent: event});
  }
}
