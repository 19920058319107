// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-form-field {
  width: 100%;
}

.bt-spinner {
  display: inline;
  padding-right: 30px;
}

.f-field {
  margin-bottom: 15px;
}

.field-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/admin/cards/components/dialogs/bespokedialog/bespoke-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,eAAA;EACA,mBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,0BAAA;EACA,eAAA;EACA,mBAAA;EACA,cAAA;AACJ","sourcesContent":["mat-form-field {\n    width:100%;\n}\n\n.bt-spinner {\n    display: inline;\n    padding-right: 30px;\n}\n\n.f-field {\n    margin-bottom: 15px;\n}\n\n.field-label {\n    color: rgba(0,0,0,.54);\n    font-size: 14px;\n    margin-bottom: 10px;\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
