import { Component, Inject, Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FileServer } from 'src/app/services/fileserver.service';

@Component({
  selector: 'grid-preview',
  templateUrl: './grid-preview.component.html',
  styleUrls: ['./grid-preview.component.scss'],
})
export class GridPreview {
  public btn_save_disabled: boolean = false;
  public loader: boolean = false;
  public calendar = null;
  public loading = false;
  public headerWidth = [];

  @Input() withFooter: boolean = true;
  @Input() set data(data) {
    if(data) {
      this._data = data;
      this.buildCalendar(this._data);
      this.setHeaderWidth();
    } else {
      this._data = [];
    }
  }
  protected _data = [];

  @Input() cssClass: string = '';
  @Input() gridCssClass: string = '';
  @Input() gridType: string = null;

  constructor(
    private translate: TranslateService,
    private fileserver: FileServer,
  ) {
    // if(this._data) {
    //   this.buildCalendar(this._data);
    //   this.setHeaderWidth();
    // }
  }

  ngOnInit() {}

  buildCalendar(data) {
    let calendar = [];
    if(data) {
      data.forEach((s , key)=> {
        let row = [];
        if(key === 0) { // header
          s.forEach((cell, index) => {
            if(index == 0 || cell.text !== null) {
              row.push(cell);
            }
          });
        } else if(key === (data.length-1) && this.withFooter) { // footer
          s.forEach((cell , index) => {
            if(index == 0 || data[0][index]['text'] !== null) {
              row.push(cell);
            }
          });
        } else { // body
          s.forEach((cell , index) => {
            if((index == 0  && (cell.text !== null || (cell.logo && cell.logo.path))) || (data[0][index]['text'] !== null && (data[key][0]['text'] !== null || (data[key][0]['logo'] && data[key][0]['logo']['path'])))) { // display the cell only if the header has value, and first column cell has value
              row.push(cell);
            }
          });
        }
        if(row.length) { 
          calendar.push(row);
        }
      })
    }
    this.calendar = calendar;
  }


  setHeaderWidth() {
    let headerWidth = [];
    if(this.calendar && this.calendar.length) {
      let header = this.calendar[0];
      let originalWidth = [];
      header.forEach((h, index )=> {
        let celWidth = this.cellWidth(h);
        originalWidth.push(celWidth)
      });

      let finalWidth = [];
      const nnullable = originalWidth.filter(element => {
        return element !== null;
      });
      const nullable = originalWidth.filter(element => {
        return element === null;
      });
      
      originalWidth.forEach(h => {
        if(h !== null) {
          finalWidth.push(h);
        } else {
          let w = 100 - nnullable.reduce((a, b) => a + b, 0);
          finalWidth.push(w / nullable.length);
        }
      });
      headerWidth = finalWidth;
    }
  
    this.headerWidth = headerWidth;
  }

  cellWidth(cell) {
    if(cell.settings && cell.settings.width) {
      return cell.settings.width;
    }
    return null;
  }

  getLogo(logoPath) {
    return logoPath ? this.fileserver.getMediaFile(logoPath) : null;
  }
}
