import { DataService } from './../../../services/data.service';
import { PusherService } from './../../../services/pusher.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NavigationService } from 'src/app/services/navigation.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../confirmdialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { BookmarkService } from 'src/app/services/bookmark.service';

@Component({
  selector: 'bookmark-dialog',
  templateUrl: './bookmark-dialog.component.html',
  styleUrls: ['./bookmark-dialog.component.scss']
})
export class BookmarkDialog  {
  public form: FormGroup = null;
  public mode: string = null;
  public bookmarkData = null;
  
  constructor(
    public dialogRef: MatDialogRef<BookmarkDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    public pusher : PusherService,
    protected dataService : DataService,
    private bookmarkService: BookmarkService,
    public dialog: MatDialog,
    private translate: TranslateService
    ) {
      this.form = new FormGroup({
        name: new FormControl(null,[Validators.required])
      }, {
        updateOn: 'change'
      })

      if(data.bookmark) {
        this.mode = 'update';
        this.bookmarkData = data.bookmark;
        this.form.patchValue(this.bookmarkData.bookmark);
      } else {
        this.mode = 'creade';
      }
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setData() {
    let data = this.form.value;
    return data;
  }

  save() {
    this.reValidateForms();
    let data = this.setData();
    if(this.form.valid) {
      if(this.mode == 'update') {
        this.update(data);
      } else {
        this.create(data);
      }
      this.dialogRef.close('bt_save');
    }
  }

  create(data) {
    this.bookmarkService.createBookmarksInStorage(data.name);
  }

  update(data) {
    let bookmark = this.bookmarkData.bookmark;
    let module = this.bookmarkData.module;
    this.bookmarkService.updateBookmarksInStorage(bookmark, module, data.name);
  }

  onDeleteBookmark() {
    if(this.bookmarkData) {
      const dialogRef = this.dialog.open(ConfirmDialog, {
        data: {
          title: this.translate.instant('BOOKMARK_DIALOG.DELETE_CONFIRM_TITLE'),
          text: this.translate.instant('BOOKMARK_DIALOG.DELETE_CONFIRM_TEXT'),
        },
        autoFocus: false
      }); dialogRef.afterClosed().subscribe(result => {
        if (result == 'bt_yes') {
          this.bookmarkService.onBookmarkDelete(this.bookmarkData.bookmark, this.bookmarkData.module);
          this.dialogRef.close('bt_delete');
        }
      });
    }
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }

  reValidateForms() {
    this.form.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    for (let f in this.form.controls) {
      this.form.controls[f].markAsTouched();
      this.form.controls[f].updateValueAndValidity({ emitEvent: false });
    }
  }
}
