// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bt-spinner {
  display: inline;
  padding-right: 30px;
}

form {
  padding: 10px;
}
form .group-label {
  flex: 1;
  align-items: center;
  display: flex;
}
form .group-fields {
  flex: 5;
}
form .radio-group-field mat-error {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dialogs/publishdialog/publish-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;AACF;AAAE;EACE,OAAA;EACA,mBAAA;EACA,aAAA;AAEJ;AAAE;EACE,OAAA;AAEJ;AACI;EACI,eAAA;AACR","sourcesContent":[".bt-spinner {\n  display: inline;\n  padding-right: 30px;\n}\n\nform {\n  padding: 10px;\n  & .group-label {\n    flex: 1;\n    align-items: center;\n    display: flex;\n  }\n  & .group-fields {\n    flex: 5;\n  }\n  & .radio-group-field{\n    & mat-error{\n        font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
