// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-dialog-title {
  margin: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
::ng-deep .mat-list-item-content {
  word-break: break-word;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dialogs/bookmarkdialog/bookmark-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF;;AAEA,2HAAA;AACA;EACE,sBAAA;AACF","sourcesContent":[".mat-mdc-dialog-title{\n  margin: 0;\n}\n\n/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */\n::ng-deep .mat-list-item-content {\n  word-break: break-word;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
