import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridEditorDialog } from './grideditordialog/grid-editor-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FileServer } from 'src/app/services/fileserver.service';

@Component({
  selector: 'grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {
  public  grid: any[] = [];

  @Input() set gridColumns(columns) {
    this._gridColumns = columns;
  }
  protected _gridColumns = 12;

  @Input() set gridRows(rows) {
    this._gridRows = rows;
  };
  protected _gridRows = 12;

  @Input() set data(d) {
    this._data = d;

    this.buildGrid();
    this.fillGrid(this._data);
  };
  protected _data = [];

  @Input() withFooter: boolean = true;
  @Input() cssClass: string = "";
  @Input() withLogo: boolean = false; 

  @Output() onGridUpdate: EventEmitter<any> = new EventEmitter();
  

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private fileserver: FileServer
  ) { }

  ngOnInit() {
  }

  reBuildGrid(data) {
    this.buildGrid();
    this.fillGrid(data);
    this.onGridUpdate.emit({ grid: this.grid });
  }

  buildGrid() {
    // build grid
    this.grid = [];
    for(let i=1; i<=this._gridRows; i++) {
      let line =  Array(this._gridColumns).fill({text: null, settings: null});
      if(i !== 1 && this.withLogo) {
        line =  Array(this._gridColumns).fill({text: null, settings: null, logo: {table: null, path: null, name: null}});
      }
      this.grid.push(line);
    }
  }

  fillGrid(data : any []) {
    if(data) {
      data.forEach((d, dindex) => {
        d.forEach((cell, cindex) => {
          if(typeof this.grid[dindex] !== 'undefined' && typeof this.grid[dindex][cindex] !== 'undefined') {
            this.grid[dindex][cindex] = {};
            this.grid[dindex][cindex]['text'] = cell.text || null;
            this.grid[dindex][cindex]['settings'] = cell.settings || null;
            if(dindex !=0 && this.withLogo) {
              this.grid[dindex][cindex]['logo'] = {};
              this.grid[dindex][cindex]['logo']['table'] = cell.logo ? cell.logo.table : null;
              this.grid[dindex][cindex]['logo']['path'] = cell.logo ? cell.logo.path : null;
              this.grid[dindex][cindex]['logo']['name'] = cell.logo ? cell.logo.name : null;
            }
          }
        });
      })
    }
  }

  openEditor(cell, row_index, cell_index, withSettings= false) {
    const dialogRef = this.dialog.open(GridEditorDialog, {
      data: {
        title: this.translate.instant('GRID.EDITOR_DIALOG.TITLE'),
        cell: cell,
        withSettings: withSettings,
        withLogo: row_index !== 0 && this.withLogo
      },
      width: '600px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && typeof result.cell !== 'undefined') {
        this.grid[row_index][cell_index] = {
          text: result.cell.text || null
        }
        if(withSettings) {
          this.grid[row_index][cell_index]['settings'] = result.cell.settings || null; 
        } 
        // save the logo
        if(row_index !== 0 && this.withLogo) {
          this.grid[row_index][cell_index]['logo']= {};
          this.grid[row_index][cell_index]['logo']['path'] = result.cell.logo ? result.cell.logo.path : null;
          this.grid[row_index][cell_index]['logo']['table'] = result.cell.logo ? result.cell.logo.table : null;
          this.grid[row_index][cell_index]['logo']['name'] = result.cell.logo ? result.cell.logo.name : null;
        }
        this.onGridUpdate.emit({ grid: this.grid });
      }
    });
  }

  getLogo(logoPath) {
    return logoPath ? this.fileserver.getMediaFile(logoPath) : null;
  }
}
