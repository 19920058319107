export interface ExchangeRate {
    year: number;
    usd : number;
    eur : number;
};

export class Currency {
    id: number = null;
    name: string = null;
    code: string = null;
    sign: string = null;
    usd: number = null;
    eur: number = null;
    exchange_rates:  Array<ExchangeRate> =  null;
    created_at: string = null;
    updated_at: string = null;


    constructor(id?: number | Object) {
        if (typeof (id) == 'object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                this[key] = data[key];
            }
        }
    }

    
};