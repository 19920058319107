import { CategoryGama } from './CategoryGama';
import { Country } from './Country';

export class ParentcoGama {
    id: number = null;
    category_gama_id: number = null;
    country_id: number = null;
    name: string = null;
    auto: boolean = null;
    parentco_count: number = null;
    forced_cards_count: number = null;
    card_count: number = null;
    updated_at: string = null;
    created_at: string = null;
    history: any = null;
    logo:any = null;
    country: Country;
    categoryGama: CategoryGama = null;


    constructor( id? : number|Object) {
        if (typeof(id)=='object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                switch(key) {
                    case 'country' :
                        this[key] = new Country(data[key]);
                        break;
                    case 'categoryGama' :
                            this[key] = new CategoryGama(data[key]);
                    break;
                    default:
                        this[key] = data[key];
                }
            }
        }
    }
};