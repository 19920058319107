import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { BookmarkDialog } from '../../dialogs/bookmarkdialog/bookmark-dialog.component';
import * as _ from 'lodash';
import { BookmarkService } from 'src/app/services/bookmark.service';

@Component({
  selector: 'bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss']
})
export class BookmarkComponent implements OnInit {
  @Input() set canAddBookmark(canAdd: boolean) {
    this._canaddBookMark = canAdd
  }
  get canAddBookmark() {
    return this._canaddBookMark;
  }
  private _canaddBookMark: boolean = false;

  @ViewChild(MatMenuTrigger) bookmarksMenu: MatMenuTrigger;
  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    public bookmarkService: BookmarkService
  
  ) { }

  ngOnInit() {
  }

  onAddBookmark() {
    const dialogRef = this.dialog.open(BookmarkDialog, {
      data: {
        title: this.translate.instant('BOOKMARKS.DIALOG_ADD_TITLE'),
      },
      autoFocus: false,
    })
  }

  onEditBookmark(bookmark, module, event) {
    this.bookmarksMenu.closeMenu();
    const dialogRef = this.dialog.open(BookmarkDialog, {
      data: {
        title: this.translate.instant('BOOKMARKS.DIALOG_EDIT_TITLE'),
        bookmark: {
          module: module,
          bookmark: bookmark
        }
      },
      autoFocus: false,
    })
  }

  getBookmarksList() {
    this.bookmarkService.getStorageBookmarks();
  }

  replayBookmark(bookmark, event) {
    this.bookmarkService.replayBookmark(bookmark);
    this.bookmarksMenu.closeMenu();
  }

  onActionsButtonClick(event) {
    event.stopPropagation();
  }
}
