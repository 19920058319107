import { Country } from './Country';
import { Pitch } from './Pitch';

export class Consultant {
    id: number = null;
    country_id: number = null;
    name : string = null;
    card_count: number = null;
    updated_at: string = null;
    created_at: string = null;
    logo: string = null;
    linkedin_url: string = null;
    linkedin_tag: string = null;
    twitter_tag: string = null;

    country: Country = null;
    edit_token : string = null;
    mapicoservices:any[] = null;
    mainclients:any = null;
    mainpitches:Pitch[] = null;
    network = null;
    history: any = null;
    consultant_updates: any = null;
    opmodel_local:boolean = false;
    opmodel_central:boolean = false;
    opmodel_description:string = null;
    opmodel_checkboxes_status:string = null;
    opmodel_description_status:string = null;
    service_description_status:string = null;
    services_description:string = null;

    constructor( id? : number|Object) {
        if (typeof(id)=='object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                switch(key) {
                    case 'country':
                        this[key] = new Country(data[key]);
                        break;
                    default:
                        this[key] = data[key];
                }
                
            }
        }
    }

};