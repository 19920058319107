// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-tab-body-wrapper {
  padding-top: 20px !important;
}

::ng-deep mat-tab-group .mat-mdc-tab-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
  background: white;
}

.fixActionRow {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.spacer {
  flex-grow: 1;
}

.bt-spinner {
  display: inline;
  padding-right: 30px;
}

.full-width {
  width: 100%;
}

quill-editor::ng-deep .ql-editor {
  min-height: 100px;
}

.tab-content {
  padding-top: 15px;
}

.grid-logo-container img {
  width: 70px;
}

.capitalize {
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/app/components/misc/grid/grideditordialog/grid-editor-dialog.component.scss"],"names":[],"mappings":"AACA;EACE,4BAAA;AAAF;;AAKE;EACE,gBAAA;EACA,wBAAA;EACA,MAAA;EACA,aAAA;EACA,iBAAA;AAFJ;;AAOA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;AAJF;;AAOA;EACE,YAAA;AAJF;;AAOA;EACE,eAAA;EACA,mBAAA;AAJF;;AAOA;EACE,WAAA;AAJF;;AAQE;EACE,iBAAA;AALJ;;AASA;EACE,iBAAA;AANF;;AAUE;EACE,WAAA;AAPJ;;AAWA;EACE,0BAAA;AARF","sourcesContent":["\n::ng-deep .mat-tab-body-wrapper {\n  padding-top: 20px !important;\n}\n\n::ng-deep mat-tab-group {\n  // sticky tab header\n  .mat-mdc-tab-header {\n    position: sticky;\n    position: -webkit-sticky;\n    top: 0;\n    z-index: 1000;\n    background: white;\n  }\n}\n\n\n.fixActionRow {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.spacer {\n  flex-grow: 1;\n}\n\n.bt-spinner {\n  display: inline;\n  padding-right: 30px;\n}\n\n.full-width{\n  width:100%;\n}\n\nquill-editor::ng-deep {\n  .ql-editor {\n    min-height: 100px;\n  }\n}\n\n.tab-content {\n  padding-top: 15px;\n}\n\n.grid-logo-container {\n  img {\n    width: 70px;\n  }\n}\n\n.capitalize {\n  text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
