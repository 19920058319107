// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-wrapper {
  display: flex;
  align-content: center;
  align-items: center;
}
.item-wrapper mat-icon {
  color: rgba(0, 0, 0, 0.4588235294);
  margin-right: 20px;
}
.item-wrapper mat-icon.svg-icon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dialogs/eventsdialog/events-dialog.component.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;AAAF;AAEE;EACE,kCAAA;EACA,kBAAA;AAAJ;AACI;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AACN","sourcesContent":["\n.item-wrapper {\n  display: flex;\n  align-content: center;\n  align-items: center;\n\n  mat-icon {\n    color: #00000075;\n    margin-right: 20px;\n    &.svg-icon {\n      width: 30px;\n      height: 30px;\n      margin-right: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
