import { EventsDialog } from './../dialogs/eventsdialog/events-dialog.component';
import { PusherService } from './../../services/pusher.service';
import { AuthUser } from './../../models/AuthUser';
import { User } from './../../models/User';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { Component, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';
import { MatExpansionPanel } from '@angular/material/expansion';
import { DataService } from 'src/app/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logged-layout',
  templateUrl: './logged-layout.component.html',
  styleUrls: ['./logged-layout.component.scss']
})
export class LoggedLayoutComponent {
  user: User = null;
  authUser: AuthUser = null;
  sideMenu = [];
  profileMenu = [];
  ready = false;
  nb_notifications = 0;
  public isProd: boolean = false;
  public isDemo: boolean = false;
  public hideFilter: boolean = false;
  public filter: string = '';

  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    public translate: TranslateService,
    public auth: AuthenticationService,
    public navService: NavigationService,
    public pusher: PusherService,
    public dialog: MatDialog,
    public data: DataService,
    private router:Router
  ) {
    this.isProd = environment.production;
    this.isDemo = !environment.production && (window.location.hostname.includes(".demo."));

    this.auth.currentAuthUser.subscribe((user) => {
      this.authUser = user;

      const menu = this.navService.getMenu('main');
      // menu.forEach((item) => {
      //   if (item.label) {
      //     item.label = this.translate.instant(item.label);
      //   }
      //   if (item.items) {
      //     item.items.forEach((item) => {
      //       if (item.label) {
      //         item.label = this.translate.instant(item.label);
      //       }
      //     });
      //   }
      // });
      this.sideMenu = menu;
      this.profileMenu = this.navService.getMenu('profile');
      this.ready = true;
    });
    this.auth.currentUser.subscribe((user) => {
      this.user = user;
    });

    this.pusher.eventsChanged.subscribe((n) => {
      this.nb_notifications = n;
      if (this.data.downloads) {
        this.data.downloads--;
      }
    });
  }

  isItemVisible(item = null) {
    let ret = true;
    if (item && item.roles) {
      ret = this.authUser && this.authUser.hasRole(item.roles);
    } 

    if (this.filter.trim().length > 0) {
      if (item.label && this.filter.trim().length > 0) {
        ret = item.label.toLowerCase().indexOf(this.filter.toLowerCase()) != -1;
      } 
      
      if (item.type == 'group' && !ret) {
        // search in items
        for(let i=0; i< item.items.length; i++) {
          if (item.items[i].label && item.items[i].label.toLowerCase().indexOf(this.filter.toLowerCase()) != -1) {
            ret = true;
            break;
          }
        }
      }
    }
    return ret;
  }

  showEventsDialog() {
    const dialogRef = this.dialog.open(EventsDialog, {
      data: {
        title: this.translate.instant('NOTIFICATIONS.DIALOG_TITLE'),
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        this.nb_notifications = this.nb_notifications - 1;
      }

      if (result && result == 'bt_clear') {
        this.pusher.events = [];
        this.nb_notifications = 0;
      }
    });
  }

  minimizeFilter() {
    this.hideFilter = !this.hideFilter;
    if (this.hideFilter) {
      this.panels.forEach((panel) => {
        panel.close();
      });
    }
  }

  openFilter() {
    if (this.hideFilter) {
      this.hideFilter = false;
    }
  }

  getLabelTooltip(label) {
    return this.hideFilter ? this.translate.instant(label) : null;
  }

  onFilterChange(value) {
    //console.log('onFilterChange', value);
  }
  
  onFilterEnter(value) {
    //console.log('onFilterEnter', value,this.sideMenu);
    const items = [];
    this.sideMenu.forEach(item=>{
      //console.log('item', item)
      switch(item.type) {
        case 'group':
          item.items.forEach(subitem=>{
            switch(subitem.type) {
              case 'item':
                if ( this.isItemVisible(subitem)) {
                  items.push(subitem);
                }
            }
          })
        break;
        case 'item':
          if ( this.isItemVisible(item)) {
            items.push(item);
          }
      }
    })

    if (items.length==1) {
      // go to the item
      this.router.navigateByUrl(items[0].path);
    }

  }
}
