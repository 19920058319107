import { Group } from './Group';

export class AgencyNetwork {
    id: number = null;
    group_id: number = null;
    name: string = null;
    logo: string = null;
    color: string = null;
    isInIntranetwork: boolean = null;
    isVisibleBMS: boolean = null;
    isVisibleGAMA: boolean = null;
    isVisibleNBB: boolean = null;
    isVisibleCARD: boolean = null;
    isVisiblePREDICTOR: boolean = null;
    isVisibleMCA: boolean = null;
    isMajor: boolean = null;
    isIndependent: boolean = null;
    updated_at: string = null;
    created_at: string = null;
    history: any = null;

    group: Group = null;

    constructor(id?: number | Object) {
        if (typeof (id) == 'object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                this[key] = data[key];

            }
        }
    }
};