import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import * as _ from 'lodash';
import { Admonitoring } from 'src/app/models/Admonitoring';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'search-admo',
  templateUrl: './search-admo.component.html',
  styleUrls: ['./search-admo.component.scss']
})
export class SearchAdmo implements OnInit {
  public ready = false;
  public form: UntypedFormGroup = null;
  public btn_save_disabled: boolean = false;
  public btn_save_loader: boolean = false;
  public admos = [];
  public admosReady: boolean = true;
  public selectedAdmo: number = null;
  public selectedAdmos: any[] = [];

  @Input() set selectedAdmosIds(admos) {
    if(admos) {
      this.selectedAdmos = admos;
    } else {
      this.selectedAdmos = [];
    }
  }
  @Input() microsoftAdmo:boolean = false;
  @Input() extraParameters = null;
  @Input() doSearch: boolean = true;
  @Input() set searchInputValue(v) {
    this._searchInputValue = v;
    this.form.get('advertiser_name').patchValue(v);
    setTimeout(() => {
      this.onSearchAdmos(v);
    });
  }
  private _searchInputValue: string = null;

  @Input() set admonitoring(a) {
    this._admo = a;
    this.prepareData();
  }
  private _admo: Admonitoring = null;

  @Input() year: number = null;
  @Input() month: string = null;
  @Input() country_id: string = null;

  @Output() onAdmoSelect: EventEmitter<any> = new EventEmitter();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private dataService: DataService,
    private sanitizer: DomSanitizer,
    private router: Router,
    public dialog: MatDialog
    ) { 
      this.form = new UntypedFormGroup({
        advertiser_name: new UntypedFormControl(null,[])
      }, {
        updateOn: 'change'
      });
  }

  ngOnInit(): void {
    let proms = [];
    Promise.all(proms).then(res => {
      this.ready = true;
    })
  }

  prepareData() {
    if(this._admo && this._admo.siblings) {
      this.selectedAdmos = this._admo.siblings.map(s => {
        return s.id;
      });
    }
    
    if(this.doSearch) {
      let advertiser_name = null;
      if(this._admo) {
        advertiser_name = this._admo.admo_name_cleaned !== null ? this._admo.admo_name_cleaned.substring(0,5) : null;
      }
      if(this._searchInputValue) {
        advertiser_name = this._searchInputValue;
      }
      this.form.get('advertiser_name').patchValue(advertiser_name);
      setTimeout(() => {
        this.onSearchAdmos(advertiser_name);
      })
    }
  }

  private _filterTimeout = null;
  public admos_ids = {};
  searchAdmos(data) {
    return new Promise((resolve, reject) => {
      if (this._filterTimeout) {
        clearTimeout(this._filterTimeout);
      }

      if(this.extraParameters) {
        data = {
          ...data,
          ...this.extraParameters
        };
      }

      this._filterTimeout = setTimeout(() => {
        this.admosReady = false;
        this.admos = [];
        this.admos_ids = {};
        let url = this.microsoftAdmo ? `admin/ms-admonitorings/lists/admos` : `admin/admonitorings/lists/admos`;
        return this.dataService.getAsPromise(url, {params: data}).then(res => {
          if(res && res.data) {
            this.admos = res.data;
          }
          this.admosReady = true;
          resolve(this.admos);
        }).catch(err => {
          console.log(err);
          this.admosReady = true;
          reject(err);
        })
      }, 700);
    })
  }

  onSearchAdmos(advertiser_name) {
    if(!this.country_id && !this.year && !!this.month) {
      return;
    }
    let data = {
      advertiser_name: advertiser_name,
      country_id: this.country_id,
    }

    if(this.year) {
      data['year'] = this.year;
      data['month'] = this.month;
    }
    this.searchAdmos(data).then(res => {
      this.selectedAdmo = null;
    }).catch(err => {
      this.selectedAdmo = null;
    });

    this.onSearch.emit(advertiser_name);
  }

  round(v) {
    return Math.round((v + Number.EPSILON) * 10) / 10;
  }

  AllowStylingInnerHTML(htmlTextWithStyle) {
    if(!htmlTextWithStyle) {
      return null;
    }
    htmlTextWithStyle = htmlTextWithStyle.replace(/\n/g, '<br/>');
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  onAdmoSelectEvent(event) {
    let o = _.find(this.admos, {id: event.value});
    let admo = null;
    if(o) {
      admo = o;
    }
    this.onAdmoSelect.emit(admo);
  }

  select(admo) {
    if(!admo || ! admo.id) {
      return null;
    }

    this.onAdmoSelectEvent({value: admo.id})
  }
  inArray(val, array) {
    if(array) {
      return array.includes(val);
    }
    return false;
  }

  isAdmoAlreadyMatched(admo) {
    if(admo) {
      if(admo.card_id != null && admo.card_id != '') {
        if(!this._admo || (this._admo && ! this.inArray(admo.id, this._admo.data))) {
          return true;
        }
      } else if(admo.topic == 'split') { // split parent(with card_id = null)
        return true;
      }
    }
    return false;
  }
}
