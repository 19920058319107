// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-menu-item[disabled] mat-icon {
  color: rgba(0, 0, 0, 0.38);
}`, "",{"version":3,"sources":["webpack://./src/app/components/menus/dropdownmenu/dropdownmenu.component.scss"],"names":[],"mappings":"AACI;EACI,0BAAA;AAAR","sourcesContent":[".mat-mdc-menu-item[disabled] {\n    mat-icon {\n        color: rgba(0, 0, 0, 0.38);\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
