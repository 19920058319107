import { DataService } from './../../../services/data.service';
import { PusherService } from './../../../services/pusher.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileServer } from 'src/app/services/fileserver.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'events-dialog',
  templateUrl: './events-dialog.component.html',
  styleUrls: ['./events-dialog.component.scss']
})
export class EventsDialog {

  constructor(
    public dialogRef: MatDialogRef<EventsDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    public pusher: PusherService,
    protected dataService: DataService,
    public fs: FileServer,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  download(event,item) {
    // console.log('DOWNLOAD', event);
    if (event) {
      if(item && item.type == 'file_download') {
        this.dataService.getFileFromEvent(event)
        .then(() => {
          // console.log('FILE DOWNLOADED', event)
          this.pusher.removeEvent(event);
          this.dialogRef.close({ event });
        })
      } else if( item && item.type == 'nbb_download') {
        this.fs.downloadFile(environment.api.root+item.event.uri)
        .then(() => {
          // console.log('FILE DOWNLOADED', event)
          this.pusher.removeEvent(event);
          this.dialogRef.close({ event });
        })
      } else if(item && item.type == 'billing_build') {
        this.pusher.removeEvent(event);
        this.dialogRef.close({ event });
      } else {
        this.pusher.removeEvent(event);
        this.dialogRef.close({ event });
      }
    }
  }
}
