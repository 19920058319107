import { ParentcoGama } from './ParentcoGama';

export class Parentco {
    id: number = null;
    parentco_gama_id: number = null;
    name: string = null;
    advertiser_count: number = null;
    card_count: number = null;
    updated_at: string = null;
    created_at: string = null;
    history: any = null;

    parentcoGama: ParentcoGama;


    constructor( id? : number|Object) {
        if (typeof(id)=='object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                switch(key) {
                    case 'parentco_gama' :
                        this[key] = new ParentcoGama(data[key]);
                        break;
                    default:
                        this[key] = data[key];
                }
            }
        }
    }
};