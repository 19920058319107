import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialog  {

  public customButtons: [] = null;
  public contentType = null;
  public cancel_bt_type = 'raised';
  
  constructor(
    public dialogRef: MatDialogRef<InfoDialog>,
    @Inject(MAT_DIALOG_DATA) public data) {
      if(data && data.customButtons) {
        this.customButtons = data.customButtons;
      }
      if(data && data.contentType) {
        this.contentType = data.contentType;
      }
      if(data && data.cancel_bt_type) {
        this.cancel_bt_type = data.cancel_bt_type;
      }
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}