import { CategoryGama } from './CategoryGama';
import { Parentco } from './Parentco';

export class Advertiser {
    id: number = null;
    parentco_id: number = null;
    category_gama_id: number = null;
    name: string = null;
    website: string = null;
    brand_count: number = null;
    job_count: number = null;
    card_count: number = null;
    updated_at: string = null;
    created_at: string = null;
    linkedin_url: string = null;
    linkedin_tag: string = null;
    twitter_tag: string = null;
    logo: string = null;

    parentco: Parentco = null;
    categoryGama: CategoryGama = null;
    categories: any[] = null;
    brands: any[] = null;
    history: any = null;


    constructor( id? : number|Object) {
        if (typeof(id)=='object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                switch(key) {
                    case 'parentco' :
                        this[key] = new Parentco(data[key]);
                        break;
                    case 'categoryGama' :
                        this[key] = new CategoryGama(data[key]);
                        break;    
                    default:
                        this[key] = data[key];
                }
            }
        }
    }
};