import { Zone } from './Zone';

export class Subzone {
    id: number = null;
    name: string = null;
    color: string = null;
    position: number = null;
    zone_id: number = null;
    country_count: number = null;
    updated_at: string = null;
    created_at: string = null;

    zone: Zone = null;

    constructor( id? : number|Object) {
        if (typeof(id)=='object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                switch(key) {
                    case 'zone':
                        this[key] = new Zone(data[key]);
                        break;
                    default:
                        this[key] = data[key];
                }
            }
        }
    }
};