import { Component, Inject, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormControl,UntypedFormGroup,Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { AutocompleteField, OptionColumns } from 'src/app/components/forms/autocomplete-field/autocomplete-field.component';

@Component({
  selector: 'grid-editor-dialog',
  templateUrl: './grid-editor-dialog.component.html',
  styleUrls: ['./grid-editor-dialog.component.scss'],
})
export class GridEditorDialog {
  public btn_save_disabled: boolean = false;
  public loader: boolean = false;
  public item = null;
  public mode = null;
  public form: UntypedFormGroup;
  public loading = false;
  protected save_clicked = false;
  public server_errors = null;
  public from = null;
  public cell = null;
  protected withSettings: boolean = null;
  protected withLogo: boolean = null;

  public editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline'],

      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],

      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'color': [] }],
      [{ 'align': [] }],
      ['link', 'image'],
    ]
  };

  public logos_endpoint: string = null;
  public showLogoField: boolean = false;
  public tablesLabel = {
    agencies: this.translate.instant('GRID_EDITOR_DIALOG.AGENCIES'),
    groups: this.translate.instant('GRID_EDITOR_DIALOG.GROUPS'),
    holdings: this.translate.instant('GRID_EDITOR_DIALOG.HOLDINGS'),
    consultants: this.translate.instant('GRID_EDITOR_DIALOG.CONSULTANTS'),
    advertisers: this.translate.instant('GRID_EDITOR_DIALOG.ADVERTISERS'),
    parentco_gamas: this.translate.instant('GRID_EDITOR_DIALOG.PARENTCO_GAMAS')
  };
  public logoTables: any[] = [
    { value: null, label: ''},
    { value: 'agencies', label: this.translate.instant('GRID_EDITOR_DIALOG.AGENCIES')},
    { value: 'groups', label: this.translate.instant('GRID_EDITOR_DIALOG.GROUPS')},
    { value: 'holdings', label: this.translate.instant('GRID_EDITOR_DIALOG.HOLDINGS')},
    { value: 'consultants', label: this.translate.instant('GRID_EDITOR_DIALOG.CONSULTANTS')},
    { value: 'advertisers', label: this.translate.instant('GRID_EDITOR_DIALOG.ADVERTISERS')},
    { value: 'parentco_gamas', label: this.translate.instant('GRID_EDITOR_DIALOG.PARENTCO_GAMAS')},
  ];
  public logoOptionsColumns: OptionColumns = {
    id: 'id',
    label: 'name',
    operator: 'contain'
  };

  @ViewChild('logoAutocomplete', { static: false }) logoAutocomplete: AutocompleteField;

  constructor(
    public dialogRef: MatDialogRef<GridEditorDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private translate: TranslateService,
  ) {
    this.form = new UntypedFormGroup({
      text: new UntypedFormControl(null,[]),
    }, {
      updateOn: "change"
    });

    if(data && data.cell) {
      this.cell = data.cell;
    }

    if(data && typeof data.withSettings !== 'undefined') {
      this.withSettings = data.withSettings;
    }

    if(data && typeof data.withLogo !== 'undefined') {
      this.withLogo = data.withLogo;
    }

    if(this.withSettings) {
      this.form.addControl('settings',new UntypedFormGroup({
        width: new UntypedFormControl(null,[]),
      }, {
        updateOn: "change"
      }));
    }

    this.form.patchValue(this.cell);

    if(this.withLogo) {
      this.form.addControl('logo',new UntypedFormGroup({
        table: new UntypedFormControl(null,[]),
        path: new UntypedFormControl(null,[this.logoPathValidation.bind(this)]),
        name: new UntypedFormControl(null,[]),
      }, {
        updateOn: "change"
      }));

      if(this.cell.logo) {
        let logo  = {table: this.cell.logo.table || null, 
          path: this.cell.logo.path ? {
            id: this.cell.logo.path,
            label: this.cell.logo.name
          }: null,
          name: this.cell.logo.name || null
        };
        this.form.patchValue({logo : logo});
        if(this.cell.logo.table) {
          this.logos_endpoint = `admin/radars/logos/${this.cell.logo.table}`;
          this.showLogoField = true;
        }
      }
    }
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    this.reValidateForms();
    if(this.form.valid) {
      this.dialogRef.close({cell: this.form.value})
    } else {
      console.log('Form not valid!');
    }
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }

  reValidateForms() {
    this.form.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    for (let f in this.form.controls) {
      this.form.controls[f].markAsTouched();
      this.form.controls[f].updateValueAndValidity({ emitEvent: false });
      if(this.form.controls[f]['controls']) {
        for (let ff in this.form.controls[f]['controls']) {
          this.form.controls[f]['controls'][ff].markAsTouched();
          this.form.controls[f]['controls'][ff].updateValueAndValidity({ emitEvent: false });
        }
      }
    }
  }

  setFocus(editor) { editor.setSelection(editor.getLength(), 0); }

  onLogoTableChange(table: string): void {
    this.form.get('logo').get('path').patchValue(null);
    if(table) {
      this.logos_endpoint = `admin/radars/logos/${table}`;
      this.showLogoField = true;
      setTimeout(() => {
        this.logoAutocomplete.loadField();
      })
    } else {
      this.showLogoField = false;
    }
  }

  onLogoChange(event: any): void {
    if(event) {
      this.form.get('logo').get('name').patchValue(event.label);
    } else {
      this.form.get('logo').get('name').patchValue(null);
    }
  }

  hasLogoError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form['controls']['logo']['controls'][controlName].hasError(errorName);
  }

  logoPathValidation(control: AbstractControl) {
    const parent = control["_parent"];
    if (parent) {
      if (!control.value && parent.controls['table'].value) {
        return { logoRequired: true };
      }
    }
    return null;
  }
}
