import { AgencyNetwork } from './AgencyNetwork';
import { AgencyUnivers } from './AgencyUnivers';
import { BillingGrowth } from './BillingGrowth';
import { Country } from './Country';
import { Segment } from './Segment';

export class Agency {
    id: number = null;
    agency_network_id: number = null;
    agency_univers_id: number = null;
    segment_id: number = null;
    hq_country_id: number = null;
    name: string = null;
    nameInt: string = null;
    logo: string = null;
    linkedin_url: string = null;
    linkedin_tag: string = null;
    twitter_tag: string = null;
    isSetting: boolean = null;
    isVisibleCONSTELLATION: boolean = null;
    isAgencyOOH: boolean = null;
    isInIntranetwork: boolean = null;
    isVisibleBMS: boolean = null;
    isVisibleCARD: boolean = null;
    isVisibleMASU: boolean = null;
    isVisibleNBB: boolean = null;
    isVisibleTALENT: boolean = null;
    isVisiblePREDICTOR: boolean = null;
    isVisibleMCA: boolean = null;
    isIndependent: boolean = null;
    card_count: number = null;
    updated_at: string = null;
    created_at: string = null;
    history: any = null;
    
    country: Country = null;
    agency_network: AgencyNetwork = null;
    segment: Segment = null;
    agency_univers: AgencyUnivers = null;

    growths: any[] = null;
    comment: string = null;

    constructor(id?: number | Object) {
        if (typeof (id) == 'object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                switch(key) {
                    case 'country' :
                        this[key] = new Country(data[key]);
                        break;
                    case 'agency_network' :
                        this[key] = new AgencyNetwork(data[key]);
                        break;
                    case 'segment' :
                        this[key] = new Segment(data[key]);
                        break;
                    case 'agency_univers' :
                        this[key] = new AgencyUnivers(data[key]);
                        break;
                    // case 'growths' :
                    //     if (data[key]) {
                    //         this[key] = [];
                    //         data[key].forEach(item => {
                    //             this[key].push(new BillingGrowth(item));
                    //         });
                    //     }
                    //     break;
                    
                    default:
                        this[key] = data[key];
                }

            }
        }
    }
};