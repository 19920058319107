import { Component, Inject} from '@angular/core';
import {UntypedFormControl,UntypedFormGroup,Validators} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import * as _ from 'lodash';
import { OptionColumns } from 'src/app/components/forms/ajax-autocomplete-chips/ajax-autocomplete-chips.component';
import { City } from 'src/app/models/City';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'city-dialog',
  templateUrl: './city-dialog.component.html',
  styleUrls: ['./city-dialog.component.scss'],
})
export class CityDialog {

  public btn_save_disabled: boolean = false;
  public loader: boolean = false;
  public item: City = null;
  public mode = null;
  public form: UntypedFormGroup;
  public loading = false;
  protected save_clicked = false;
  public server_errors = null;
  public from = null;

  public countryFieldDisabled: boolean = false;
  public country: number = null;
  
  public ValidationMessages = {
    REQUIRED_FIELD: this.translate.instant('ERRORS.FIELD_REQUIRED'),
    UNIQUE_FIELD: this.translate.instant('ERRORS.FIELD_UNIQUE'),
  };

  public countryOptionsColumns: OptionColumns = {
    id: 'id',
    label: 'name',
  };

  constructor(
    public dialogRef: MatDialogRef<CityDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dataService: DataService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.loading = true;
    this.form = new UntypedFormGroup({
      country_id: new UntypedFormControl('', [Validators.required]),
      name: new UntypedFormControl('', [Validators.required]),
    });

    this.mode = 'new';

    if(this.data.from){
      this.from = this.data.from;
    }

    if(data && typeof data.countryFieldDisabled !== 'undefined') {
      this.countryFieldDisabled = true;
    }
    if(data && typeof data.country !== 'undefined') {
      this.country = data.country;
    }

    if (data && data.item && data.item.id) {
      this.dataService
        .getAsPromise(`admin/cities/${data.item.id}`)
        .then((result) => {
          this.item = new City(result.data);
          this.mode = this.item.exists() ? 'update' : 'new';
          this.form.patchValue(this.item);
          if (this.item.country) {
            this.form.controls['country'].patchValue({
              id: this.item.country_id,
              label: this.item.country,
            });
          }

          this.loading = false;
        })
        .catch((err) => {
          console.log('get city error', err);
        });
    } else {
      this.setCountryField(this.mode);
      this.loading = false;
    }
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    this.form.markAllAsTouched();
    this.save_clicked = true;
    let data = this.form.value;

    if (this.form.valid) {
      this.btn_save_disabled = true;
      this.loader = true;
      if (this.mode == 'update') {
        this.item.import(this.form.value);
        this.dataService
          .putAsPromise(`admin/cities/${this.item.id}`, data)
          .then((res) => {
            this.dialogRef.close('bt_save');
            this.btn_save_disabled = false;
            this.loader = false;
          })
          .catch((error) => {
            console.log('ERROR', error);
            this.setServerErrors(error);
            this.btn_save_disabled = false;
            this.loader = false;
          });
      } else {
        this.dataService
          .postAsPromise(`admin/cities`, data)
          .then((res) => {
            if(this.from && (this.from == 'movement' || this.from == 'country')){
              this.dialogRef.close(res.data);
            }
            else{
              this.dialogRef.close('bt_save');
            }
            this.btn_save_disabled = false;
            this.loader = false;
          })
          .catch((error) => {
            console.log('ERROR', error);
            this.setServerErrors(error);
            this.btn_save_disabled = false;
            this.loader = false;
          });
      }
    } else {
      console.log('form not valid');
    }
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }

  setServerErrors(error) {
    this.server_errors = error;
    for (let i in error.errors) {
      this.form.controls[i].setErrors({ serverError: true });
    }
  }

  getServerError(controlName: string) {
    if (this.server_errors && this.server_errors.errors && this.server_errors.errors[controlName]) {
      return this.translate.instant('STATIC.ERRORS.' + this.server_errors.errors[controlName].toString().replace(/\./gi, ''));
    }
    return null;
  }

  setCountryField(dialogMode) {
    if(dialogMode == 'new') {
      if(this.countryFieldDisabled && this.country && this.form.controls['country_id']) {
        this.form.controls['country_id'].patchValue({
          id: this.country['id'],
          label: this.country['name']
        });
      }
    }
  }
}
