import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ComvService {
  constructor(
  ) { }

  getFilters(fields,operator) {
      let filter = 
        {
          operator: operator,
          fields: fields
        }
      ;
      return filter;
  }
}
