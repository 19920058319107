import { Component, EventEmitter, HostListener, Input, NgZone, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { ConfirmDialog } from '../../dialogs/confirmdialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent  implements OnInit{

  public form: UntypedFormGroup;
  public ready: boolean = false;
  public btn_save_loader: boolean = false;
  public btn_save_loader_regenerate:boolean = false;
  public btn_save_disabled: boolean = true;
  public send_clicked:boolean = false;
  public confirm_msg:string = null;
  public initial_contacts: number[] = [];
  public initial_year:number = null;
  public years: number[] = [];
  public contacts_saved = false;

  @Input() objectId: number = null;
  @Input() objectType: string = null;
  @Input() item: any = null;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.confirm_msg = null;
  }

  constructor(
    private dataService: DataService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private zone:NgZone
  ) {
    this.form = new UntypedFormGroup({
      contacts: new UntypedFormControl(null, []),
      is_enabled: new UntypedFormControl(false, []),
      managed_by_group: new UntypedFormControl(false, []),
      managed_by_network: new UntypedFormControl(false, []),
      year: new UntypedFormControl(null, []),
    }, {
      updateOn: 'change',
    });
  }

  ngOnInit(){   
    if(this.item){
      if(this.item.is_enabled === 1){
        this.form.get('is_enabled').setValue(true);
      }
      if(this.item.contacts?.length > 0){
        this.form.patchValue(this.item);
        this.initial_contacts = this.item.contacts;
      }
      if(this.objectType == 'group' && this.item.config){
        //this.form.get('include_networks').patchValue(this.item.config['include_networks']);
      }else if(this.objectType == 'network' && this.item.config){
        this.form.get('managed_by_group').patchValue(this.item.config['managed_by_group']);
      }else if(this.objectType == 'agency' && this.item.config){
        this.form.get('managed_by_network').patchValue(this.item.config['managed_by_network']);
      }

      if(this.item.config && this.item.config['year']){
        this.form.get('year').patchValue(this.item.config['year']);
        this.initial_year = this.item.config['year'];
      }
      
      if(this.form.get('contacts').value){
        this.contacts_saved = true;
      }  
      
      if(this.item.years){
        this.years = this.item.years;
      }
    }
  }

  sendEmail(regenerate = false){
    //set validators for contacts, just before saving
    this.form.get('contacts').setValidators([Validators.required]);
    this.form.get('contacts').updateValueAndValidity();
    this.form.markAllAsTouched();
    this.send_clicked = true;
    if(this.form.valid){     
      if(regenerate){
        this.btn_save_loader_regenerate = true;
      }else{
        this.btn_save_loader = true;
      }      
      let data = this.form.value;
      data['obj_type'] = this.objectType;
      data['obj_id'] = this.objectId;
      data['regenerate']= regenerate;
      this.dataService.postAsPromise(`admin/workflow`,data)
      .then(res=>{
        //show confirmation message
        this.confirm_msg = this.translate.instant('WORKFLOW.DIALOG.CONFIRM_MSG');
        this.btn_save_loader = false;
        this.btn_save_loader_regenerate = false;
      })
      .catch(err=>{
        this.btn_save_loader = false;
        this.btn_save_loader_regenerate = false;
        console.log(err);
      });
    }else{
      //if the form is not valid remove validators, 
      //because the field is mandatory only for sending the email, 
      //but not for saving the group/network data
      setTimeout(()=>{
        this.form.get('contacts').setValidators([]);
        this.form.get('contacts').updateValueAndValidity();
      },2000);     
    }
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }

  onContactSelect(){
    this.btn_save_disabled = true;
  }

  onContactRemove(event){
    let item = event.id;
    if(this.initial_contacts.includes(item)){
      this.btn_save_disabled = true;
    }else{
      this.btn_save_disabled = false;
    }
  }

  onSelectChange(event){
    if(event.value && this.contacts_saved){
      this.btn_save_disabled = false;
    }else{
      this.btn_save_disabled = true;
    }
  }
}
