// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-width {
  width: 100%;
}

.calendar .row-cell {
  min-height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #cecece;
}
.calendar .row-cell.header-cell, .calendar .row-cell.footer-cell {
  background-color: #AA488D;
  color: white;
  font-weight: bold;
}
.calendar .row-cell.odd {
  background-color: white;
}
.calendar .row-cell.even {
  background-color: #FFE1F0;
}
.calendar .row-cell.centered-cell div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.calendar .row-cell.radius-top-left {
  border-top-left-radius: 4px;
}
.calendar .row-cell.radius-top-right {
  border-top-right-radius: 4px;
}
.calendar .row-cell.radius-bottom-left {
  border-bottom-left-radius: 4px;
}
.calendar .row-cell.radius-bottom-right {
  border-bottom-right-radius: 4px;
}
.calendar h1 {
  font-size: 18px;
  margin: 0px;
}
.calendar h2 {
  font-size: 16px;
  margin: 0px;
}
.calendar .grid-logo-container img {
  width: 70px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/misc/grid/gridpreview/grid-preview.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAGE;EACI,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;AAAN;AACM;EAEI,yBAAA;EACA,YAAA;EACA,iBAAA;AAAV;AAEM;EACI,uBAAA;AAAV;AAEM;EACI,yBAAA;AAAV;AAGS;EACK,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,uBAAA;AADd;AAIM;EACI,2BAAA;AAFV;AAIM;EACI,4BAAA;AAFV;AAIM;EACI,8BAAA;AAFV;AAIM;EACI,+BAAA;AAFV;AAKE;EACI,eAAA;EACA,WAAA;AAHN;AAKE;EACI,eAAA;EACA,WAAA;AAHN;AAOI;EACE,WAAA;AALN","sourcesContent":[".full-width{\n  width:100%;\n}\n\n.calendar {\n  .row-cell {\n      min-height: 50px;\n      padding-left: 15px;\n      padding-right: 15px;\n      border: 1px solid #cecece;\n      &.header-cell,\n      &.footer-cell {\n          background-color: #AA488D;\n          color: white;\n          font-weight: bold;\n      }\n      &.odd{\n          background-color: white;\n      }\n      &.even {\n          background-color: #FFE1F0;\n      }\n      &.centered-cell {\n         div {\n              display: flex;\n              flex-direction: column;\n              align-items: center;\n              height: 100%;\n              justify-content: center;\n         }\n      }\n      &.radius-top-left {\n          border-top-left-radius: 4px\n      }\n      &.radius-top-right {\n          border-top-right-radius: 4px\n      }\n      &.radius-bottom-left {\n          border-bottom-left-radius: 4px\n      }\n      &.radius-bottom-right {\n          border-bottom-right-radius: 4px\n      }\n  }\n  h1 {\n      font-size: 18px;\n      margin: 0px;\n  }\n  h2 {\n      font-size: 16px;\n      margin: 0px;\n  }\n\n  .grid-logo-container {\n    img {\n      width: 70px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
