// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headcount-wrapper {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #d0d0d0;
}
.headcount-wrapper .subtitle {
  font-size: 17px;
}
.headcount-wrapper .confirm-msg {
  color: #8db854;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.row-wrapper .year {
  flex: 0 0 calc(40% - 10px);
}
.row-wrapper .enabled {
  flex: 0 0 calc(10% - 10px);
}
.row-wrapper .toggle {
  flex: 0 0 calc(40% - 10px);
}
.row-wrapper .margin-right {
  margin-right: 10px;
}

.inner-bt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/misc/workflow/workflow.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,yBAAA;AACJ;AACI;EACI,eAAA;AACR;AAEI;EACI,cAAA;AAAR;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,2BAAA;EACA,mBAAA;AADJ;AAGI;EACI,0BAAA;AADR;AAII;EACI,0BAAA;AAFR;AAKI;EACI,0BAAA;AAHR;AAMI;EACI,kBAAA;AAJR;;AAQA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AALJ","sourcesContent":[".headcount-wrapper{\n    margin-top:20px;\n    padding:10px;\n    border:1px solid #d0d0d0;\n\n    .subtitle{\n        font-size:17px;\n    }\n\n    .confirm-msg{\n        color:#8db854;\n    }\n}\n\n.row-wrapper{\n    display: flex;\n    flex-direction: row;\n    align-items: flex-end;\n    justify-content: flex-start;\n    margin-bottom:20px;\n\n    .year{\n        flex: 0 0 calc(40% - 10px);\n    }\n\n    .enabled{\n        flex: 0 0 calc(10% - 10px);\n    }\n\n    .toggle{\n        flex: 0 0 calc(40% - 10px);\n    }\n\n    .margin-right{\n        margin-right: 10px;\n    }\n}\n\n.inner-bt{\n    display:flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
