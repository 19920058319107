import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from './../models/User';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Location } from '@angular/common';




@Injectable({
  providedIn: 'root'
})
export class FileServer {
  protected fs_images = environment.fileserver.images;
  protected fs_radar = environment.fileserver.radar;
  protected fs_media = environment.fileserver.media;

  constructor(
    protected location: Location,
    private http: HttpClient
  ) { }

  getImage(path) {
    return this.location.normalize(this.fs_images + path);
  }

  getAvatar(user: User | any, size = null) {
    if (!user.avatar) {
      return '';
    }
    let path = user.avatar;
    if (path) {
      if (size) {
        path = path.replace('/avatar.', `/${size}.`);
      }
      return this.location.normalize(this.fs_images + path);
    }
  }

  
  downloadFile(path) {
    return new Promise((resolve, reject) => {
      let url = this.location.normalize(path)
      this.http.get(url,
        { responseType: 'blob', observe: 'response' })
        .subscribe(resp => {
          const filename = resp.headers.get('Content-Filename');
          const data = resp.body;
          const element = document.createElement('a');

          element.href = URL.createObjectURL(data);
          if (filename) {
            element.download = filename;
          }
          document.body.appendChild(element);
          element.click();
          resolve(true);
        });

    });
  }

  downloadReport(path) {
    return new Promise((resolve, reject) => {
      let url = this.location.normalize(this.fs_radar + path)
      this.http.get(url,
        { responseType: 'blob', observe: 'response' })
        .subscribe(resp => {
          const filename = resp.headers.get('Content-Filename');
          const data = resp.body;
          const element = document.createElement('a');

          element.href = URL.createObjectURL(data);
          if (filename) {
            element.download = filename;
          }
          document.body.appendChild(element);
          element.click();
          resolve(true);
        });

    });
  }

  getMediaFile(url, type='logo') {
    if (!this.fs_media) {
      return this.location.normalize(this.fs_images + url);
    }
    if (url.indexOf('/uploads/')===0) {
      url = url.replace('/uploads/','');
    }
    return this.location.normalize(this.fs_media + url);


  } 


}
