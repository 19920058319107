import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'nl2br'
})
export class Nl2BrPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {}
 
 public transform(value: any, sanitize:boolean=false):string{
    if (typeof value !== 'string') {
		return value;
	}
	const result = value.replace(/(?:\r\n|\r|\n)/g, '<br />');
	return sanitize
		? this.sanitizer.sanitize(SecurityContext.HTML, result) ?? ''
		: result;
  }
}