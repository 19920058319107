import { EditUserDialog } from '../components/dialogs/userdialog/user-dialog.component';
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import * as _ from 'lodash';
import { NavigationService } from './navigation.service';


@Injectable({
  providedIn: "root"
})
export class BookmarkService {
  public lastQueryParams = null;
  public canAddBookmark: boolean = false;
  public showBookmarks: boolean = true;

  constructor(
    public auth: AuthenticationService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private router: Router,
  ) {
    const self = this;

    // get storage bookmarks
    this.getStorageBookmarks();

  }

  // ====================
  private bookmarkCallBack = null;
  public storageBookmarks = null;

  public setBookmarksCallBack(callBack = new Function()) {
    if(this.canAddBookmark) {
      this.bookmarkCallBack = callBack;
    } else {
      this.bookmarkCallBack = null;
    }
  }

  createBookmarksInStorage(name) {
    let result = this.bookmarkCallBack();
    // let result = this.getLastQueryParams();

    if(!result) {
      return
    }

    if(!this.storageBookmarks || !Object.keys(this.storageBookmarks).length) {
      this.storageBookmarks = this.getStorageBookmarks();
    }
    let bookmarks = this.storageBookmarks;

    if(!bookmarks[result.module]) {
      bookmarks[result.module] = [];
    }
    let newBookmark = {name: name, route: result.route, data: result.data};
    bookmarks[result.module].push(newBookmark);

    localStorage.setItem('comv_bookmarks', JSON.stringify(bookmarks));
  }

  updateBookmarksInStorage(bookmark, module, name) {
    if(!this.storageBookmarks || !Object.keys(this.storageBookmarks).length) {
      this.storageBookmarks = this.getStorageBookmarks();
    }
    let bookmarks = this.storageBookmarks;

    if(bookmarks[module]) {
      let b = _.find(bookmarks[module], {name: bookmark.name});
      if(b) {
        b['name'] = name;
      }
    }
    localStorage.setItem('comv_bookmarks', JSON.stringify(bookmarks));
  }

  onBookmarkDelete(bookmark, module) {
    if(this.storageBookmarks && this.storageBookmarks[module]) {
      let bookmarkIndex = _.findIndex(this.storageBookmarks[module], {name: bookmark.name})
      if(bookmarkIndex !== -1) {
        this.storageBookmarks[module].splice(bookmarkIndex,1);
        if(this.storageBookmarks[module].length == 0) {
          delete this.storageBookmarks[module];
        }
        localStorage.setItem('comv_bookmarks', JSON.stringify(this.storageBookmarks));
      }
    }
  }

  replayBookmark(bookmark) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([`${bookmark.route}/bookmark/${bookmark.name}`]);
    });
  }

  getStorageBookmarks() {
    try {
      let val = localStorage.getItem('comv_bookmarks');
      if (val) {
        this.storageBookmarks = JSON.parse(val)
      } else {
        this.storageBookmarks = {};
      }
      return this.storageBookmarks;
    } catch (e) {
      this.storageBookmarks = {};
      return {};
    }
  }

  getLastQueryParams() {
    return this.lastQueryParams;
  }

  setLastQueryParams(params) {
    this.lastQueryParams = params;
  }
}