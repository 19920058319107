import { Directive, HostListener, Input } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Directive({ selector: '[autoClose]' })
export class AutoclosePanelDirective {
  private _timer = null;

  @Input('autoClose') autoclose: boolean = true;
  @Input('closeAfterLeave') delay_out: number = 1500;
  @Input('closeAfterEnter') delay_in: number = 10000;

  @HostListener('mouseenter') onMouseEnter() { 
    if (this.autoclose) {
      this.clearTimeout();

      this._timer = setTimeout(() => {
        this.panel.close();
      }, this.delay_in);
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.autoclose) {
      this.clearTimeout();

      this._timer = setTimeout(() => {
        this.panel.close();
      }, this.delay_out);
    }
  }

  constructor(private panel: MatExpansionPanel) {}

  clearTimeout() {
    if (this._timer) {
      clearTimeout(this._timer);
    }
  }
}
