
export class CategoryGama {
    id: number = null;
    name: string = null;
    color: string = null;
    auto: boolean = null;
    category_count: number = null;
    card_count: number = null;
    parentcogama_count: number = null;
    updated_at: string = null;
    created_at: string = null;


    constructor( id? : number|Object) {
        if (typeof(id)=='object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    exists() {
        return !!this.id;
    }

    import(data) {
        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                switch(key) {
                    default:
                        this[key] = data[key];
                }
            }
        }
    }
};