import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

import * as _ from 'lodash';
import { CustomDateAdapter } from 'src/app/adapters/customdate.adapter';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'history-dialog',
  templateUrl: './history-dialog.component.html',
  styleUrls: ['./history-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class HistoryDialog {
  displayedColumns: string[] = ['value', 'until', 'action'];
  dataSource: any = null;
  disable_menu: boolean = false;
  form: UntypedFormGroup = null;
  isDirty: boolean = false;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<HistoryDialog>,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    let dataSource = [];
    const field = data.history_field || 'name';
    const history =
      data.item.history && data.item.history[field]
        ? data.item.history[field]
        : null;

    dataSource.push({
      value: data.item.name,
      date: this.translate.instant('HISTORY.TODAY'),
      editabe: false,
    });
    if (history) {
      history.forEach((o) => {
        dataSource.push({
          value: o.value,
          date: o.date,
          editable: true,
          edit: false,
        });
      });

      dataSource = _.reverse(_.sortBy(dataSource, 'date'));
    }

    this.dataSource = new MatTableDataSource<any>(dataSource);
    this.refreshTable();

    this.form = new FormGroup({
      value: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required, this.dateValidation.bind(this)]),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public activeItemIndex = null;
  edit(item, index) {
    console.log('edit', item);
    item.edit = true;
    item.editable = false;
    this.disable_menu = true;
    this.form.patchValue(item);
    this.activeItemIndex = index;
  }

  close(item, idx, save = true) {
    this.activeItemIndex = null;
    item.edit = false;
    item.editable = true;
    this.disable_menu = false;

    console.log(this.form.value);
    if (
      this.form.get('value').value == '' ||
      this.form.get('date').value == ''
    ) {
      _.pullAt(this.dataSource.data, idx);
    } else { 
      if(!this.form.valid) {
        let o = this.dataSource.data[idx];
        if(o && (o.value == '' || o.date =='')) {
          _.pullAt(this.dataSource.data, idx);
        }
        this.refreshTable();
        return;
      }

      item.value = this.form.get('value').value;

      const date = this.form.get('date').value;
      if (typeof(date)=='object') {
        item.date = date.format('YYYY-MM-DD');
      } else {
        item.date = date;
      }
      this.isDirty = true;
    }
    this.refreshTable();
  }

  delete(item, idx) {
    console.log('delete', item, idx);
    //_.remove(this.dataSource.data, (o) => o.date === item.date);
    _.pullAt(this.dataSource.data, idx);
    this.isDirty = true;
    this.refreshTable();
  }

  add() {
    const item = {
      value: '',
      date: '',
      editable: false,
      edit: true,
    };
    this.dataSource.data.push(item);
    this.form.patchValue(item);

    this.disable_menu = true;
    this.refreshTable();
  }

  closeDialog(): void {
    // Perform any actions before closing the dialog

    if (this.isDirty && this.data.model) {
      const field = this.data.history_field || 'name';
      const data = [];
      this.dataSource.data.forEach((o) => {
        if (o.editable) {
          data.push({ value: o.value, date: o.date });
        }
      });

      let newHistory = {};
      if(this.data.item.history) {
        newHistory = this.data.item.history;
      } else {
        this.data.item.history = {};
      }
      newHistory[field] = data;
      this.data.item.history[field] = newHistory[field];

      console.log(this.data.table, field, data);

      this.dataService
        .postAsPromise(
          `admin/history/${this.data.model}/${this.data.item.id}`,
          newHistory
        )
        .then((res) => {
          this.dialogRef.close('data-changed');
        });
    } else {
      this.dialogRef.close('closed');
    }
  }

  refreshTable() {
    let data = this.dataSource.data;

    // Extract the first item
    const firstItem = _.head(data);

    // Remove the first item from the array
    _.pullAt(data, 0);

    // Shuffle the remaining items
    data = _.reverse(_.sortBy(data, 'date'));

    // Reinsert the first item at the beginning
    data = _.concat([firstItem], data);

    this.dataSource.data = data;

    // Notify the data source that the data has changed
    this.dataSource.data = [...this.dataSource.data];
  }

  dateValidation(control: AbstractControl) {
    console.log(this.form)
    const parent = control["_parent"];
    if (parent) {
      if (control.value) {
        const dataSource = this.dataSource.data
        let yearAlreadyExists = false;
        dataSource.forEach((obj, key) => {
          // Check if the date starts with control value
          let date = control.value;
          if (typeof(control.value)=='object') {
            date = control.value.format('YYYY-MM-DD');
          }
          const year = date.split("-")[0];
          if(obj.date.startsWith(year) && key != this.activeItemIndex) {
            yearAlreadyExists = true;
            return;
          }
        });
        if(yearAlreadyExists) {
          return { invalidDate: true };
        }
      }
    }
    return null;
  }
}
