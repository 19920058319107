import { Component, Inject, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CardMovement } from 'src/app/models/CardMovement';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'bespoke-dialog',
  templateUrl: './bespoke-dialog.component.html',
  styleUrls: ['./bespoke-dialog.component.scss']
})
export class BespokeDialog {
  protected server_errors = null;
  public form: UntypedFormGroup;
  public ready = false;
  public btn_save_disabled = true;
  public btn_save_loader = false;
  public typeOptions: any[] = [];
  public advertiser = null;
  public agency = null;
  public agency_network = null;
  public group = null;
  public holding = null;
  public bespokeTypeOptions = [];
  public parentTypeOptions = [];

  constructor(
    public dialogRef: MatDialogRef<BespokeDialog>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data, private dataService: DataService,
    private fb: UntypedFormBuilder) {

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      type: new UntypedFormControl('', [Validators.required]),
      parent_type: new UntypedFormControl('', [Validators.required]),
      bespoke_type: new UntypedFormControl('', [Validators.required]),
    }, {
      updateOn: 'change',
    })
  }

  ngOnInit() {
    this.typeOptions = [
      { value: 'bespoke', label: this.translate.instant("BESPOKES.DIALOG.TYPES.MEDIA_DIGITAL_BESPOKE_BUSINESS") },
      { value: 'integrated', label: this.translate.instant("BESPOKES.DIALOG.TYPES.FULLY_INTEGRATED_AGENCY") },
    ];

    if(this.data) {
      this.advertiser = this.data.advertiser ?? null;
      this.agency = this.data.agency ?? null
    }
    
    if(this.agency) {
      if(this.agency.agency_network) {
        this.agency_network = this.agency.agency_network;
        if(this.agency_network.group) {
          this.group = this.agency_network.group;
          if(this.group.holding) {
            this.holding = this.group.holding;
          }
        }
      }
    }

    // set bespoke parent type options
    this.parentTypeOptions = [
      {
        label: this.translate.instant("BESPOKES.DIALOG.ADVERTISER"),
        type_name: this.advertiser ? this.advertiser.advertiser_name : null,
        value: 'advertiser'
      },
      {
        label: this.translate.instant("BESPOKES.DIALOG.PARENTCO"),
        type_name: this.advertiser ? this.advertiser.parentco_name : null,
        value: 'parentco'
      }
    ];
    // set bespoke type options
    this.bespokeTypeOptions = [
      {
        label: this.translate.instant("BESPOKES.DIALOG.AGENCY"),
        type_name: this.agency ? this.agency.name : null,
        value: 'agency'
      },
      {
        label: this.translate.instant("BESPOKES.DIALOG.AGENCY_NETWORK"),
        type_name: this.agency_network  ? this.agency_network.name : null,
        value: 'agency_network'
      },
      {
        label: this.translate.instant("BESPOKES.DIALOG.GROUP"),
        type_name: this.group  ? this.group.name : null,
        value: 'group'
      },
      {
        label: this.translate.instant("BESPOKES.DIALOG.HOLDING"),
        type_name: this.holding  ? this.holding.name : null,
        value: 'holding'
      }
    ]
    this.btn_save_disabled = false;
    this.ready = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    this.form.markAllAsTouched();
    this.create();
  }

  setData() {
    let formData = this.form.value;
    let data = {
      name: formData.name,
      type: formData.type,
    };
    
    switch(formData.parent_type) {
      case 'advertiser':
        if(this.advertiser && this.advertiser.advertiser_id) {
          data['advertiser_id'] = this.advertiser.advertiser_id;
        }
        break;
      case 'parentco':
        if(this.advertiser && this.advertiser.parentco_id) {
          data['parentco_id'] = this.advertiser.parentco_id;
        }
        break;
    }

    switch(formData.bespoke_type) {
      case 'agency':
        if(this.agency) {
          data['agency_id'] = this.agency.id;
        }
        break;
      case 'agency_network':
        if(this.agency_network) {
          data['agency_network_id'] = this.agency_network.id;
        }
        break;
      case 'group':
        if(this.group) {
          data['group_id'] = this.group.id;
        }
        break;
      case 'holding':
        if(this.holding) {
          data['holding_id'] = this.holding.id;
        }
        break;
    }
    return data;
  }

  create() {
    if (this.form.valid) {
      this.btn_save_disabled = true;
      this.btn_save_loader = true;

      let data = this.setData();
      this.dataService.postAsPromise('admin/bespokes', data).then(res => {
        if (res && res.data) {
          this.dialogRef.close({ item: res.data, mode: 'new' });
        } else {
          this.dialogRef.close('bt_save');
        }
        this.btn_save_disabled = false;
        this.btn_save_loader = false;
      }).catch(err => {
        this.setServerErrors(err);
        this.btn_save_disabled = false;
        this.btn_save_loader = false;
      });
    } else {
      console.log("Form not valid");
    }
  }

  setServerErrors(error) {
    this.server_errors = error;
    for (let i in error.errors) {
      this.form.controls[i].setErrors({ serverError: true });
    }
  }

  getServerError(controlName: string) {
    if (this.server_errors && this.server_errors.errors && this.server_errors.errors[controlName]) {
      return this.translate.instant('STATIC.ERRORS.' + this.server_errors.errors[controlName].toString().replace(/\./gi, ''));
    }
    return null;
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }
}