import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'deletecard-dialog',
  templateUrl: './deletecard-dialog.component.html',
  styleUrls: ['./deletecard-dialog.component.scss']
})
export class DeleteCardDialog  {
  public form: UntypedFormGroup = null;
  public reasons= [ 'bankrupt', 'merger', 'inactive', 'left-country', 'duplicated_card', 'other'];

  constructor(
    public dialogRef: MatDialogRef<DeleteCardDialog>,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.form = new UntypedFormGroup({
        reason: new UntypedFormControl('', [Validators.required]),
      });
  

    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  canEdit() {
    return this.form.get('reason').value && this.form.get('reason').value!='duplicated_card';
  }
  canDelete() {
    return !this.form.get('reason').value || this.form.get('reason').value=='duplicated_card';
  }
  isDisabled() {
    return !this.form.get('reason').value || this.form.get('reason').value=='other';
  }
 

}
