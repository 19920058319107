import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import * as _ from 'lodash';



@Injectable({
  providedIn: 'root'
})
export class PusherService {

  private _echo = null;
  private token = null;
  public channelId = null;
  public events = [];

  protected icons = {
    pdf: 'pdf_icon',
    xlsx: 'xls_icon',
    xls: 'xls_icon'
  };


  private eventsChangedSubject: BehaviorSubject<number>;
  public eventsChanged: Observable<number>;

  constructor(
    protected auth: AuthenticationService
  ) {
    this.eventsChangedSubject = new BehaviorSubject<number>(0);
    this.eventsChanged = this.eventsChangedSubject.asObservable();
    
    combineLatest( [this.auth.tokenRefreshed, this.auth.currentUser]).subscribe(
      ([token, user]) => {
        if (token && user) {
          this.token = token;
          this.channelId = user.notification_hash;
          if (token && this._echo) {
            this._echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + this.token;
          }
          this.subscribe();

        }
      }
    );

  }

  subscribe() {
    if (this.token && this.channelId && environment['pusher']) {
      this.events = [];
      setTimeout(() => {

        this._echo = new Echo({
          broadcaster: 'pusher',
          auth: {
            headers: {
              Authorization: 'Bearer ' + this.token
            },
          },
          authEndpoint: environment.api.root + '/auth/broadcast',
          key: environment['pusher'].key,
          cluster: environment['pusher'].cluster
        });

        let channel = this._echo.channel(`comv-${this.channelId}`);

        channel.listen('ExportSavedEvent', (e) => {
          // console.log('ExportSavedEvent', e)
        });
        channel.listen('BillingBuiltEvent', (e) => {
          // console.log('BillingBuiltEvent', e)
        });

        channel.listen('NbbBuiltEvent', (e) => {
          // console.log('NbbBuiltEvent', e)
        });
        
        channel.listen('MsAdmonitoringImportEvent', (e) => {
          // console.log('NbbBuiltEvent', e)
        });


        let privchannel = this._echo.private(`comv-${this.channelId}`);

        privchannel.listen('ExportSavedEvent', (e) => {

          if (e.event.uri) {
            e.event.svg_icon = this.getFileIcon(e.event.uri)
          }
          this.events.push(e);
          this.eventsChangedSubject.next(this.events.length);
        });
        privchannel.listen('BillingBuiltEvent', (e) => {
          this.events.push(e);
          this.eventsChangedSubject.next(this.events.length);
        })

        privchannel.listen('NbbBuiltEvent', (e) => {
          this.events.push(e);
          this.eventsChangedSubject.next(this.events.length);
        });

      }, 3000);
    }

  }

  removeEvent(event) {
    _.remove(this.events, (item)=>{
      return event.uri==item.event.uri;
    });
  }

  getFileIcon(uri) {
    if (uri) {
      const url = new URL(environment.api.root + uri);
      const path = url.pathname;
      const extension = path.split('.').pop();
      console.log('extension = ', extension); // Outputs: jpg
      if (this.icons[extension]) {
        return this.icons[extension];
      }
    }
    return null;
  }


}
