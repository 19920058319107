import { NavigationService } from 'src/app/services/navigation.service';
import { AuthenticationService } from './../services/authentication.service';
import { take, map, tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";


@Injectable({
  providedIn: "root"
})
export class AuthGuard  {
  
  constructor(
      private router: Router, 
      private auth: AuthenticationService,
      private nav: NavigationService) {}
  

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean>  {


    return this.auth.currentAuthUser.pipe(
      take(1),
      map((user) => {
        if (next.routeConfig.data && next.routeConfig.data['roles']) {
          return user.exists() && user.hasRole(next.routeConfig.data['roles']);
        }
        return user.exists();
      }),
      tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['/auth/login'], { skipLocationChange: true, queryParams:{next_url:state.url} });
          return false;
        }
        if (next.routeConfig.data && next.routeConfig.data['title']) {
          this.nav.setTitle(next.routeConfig.data['title']);
        } else {
          this.nav.setTitle(null);
        }

        this.nav.setActivatedRouteSnapshot(next);
        if(next.routeConfig.data && typeof next.routeConfig.data.showBookmarks !== 'undefined') {
          this.nav.setBookMsrksPermision(next.routeConfig.data.showBookmarks); 
        } else {
          this.nav.setBookMsrksPermision(false);
        }

        return true;
      })
    )
  }
}
