// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emoji {
  padding-right: 15px;
  width: 35px;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/app/components/forms/autocomplete-country-field/autocomplete-country-field.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,WAAA;EACA,qBAAA;AACJ","sourcesContent":[".emoji {\n    padding-right: 15px;\n    width: 35px;\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
