import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'publish-dialog',
  templateUrl: './publish-dialog.component.html',
  styleUrls: ['./publish-dialog.component.scss']
})
export class PublishDialog {
  public form: UntypedFormGroup;
  public btn_save_disabled = false;
  public btn_save_loader = false;
  public id: number = null;

  constructor(
    public dialogRef: MatDialogRef<PublishDialog>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data, private dataService: DataService) {

    // create formgroup
    this.form = new UntypedFormGroup({
      type: new UntypedFormControl("",[Validators.required]),
      news: new UntypedFormControl(true, []),
      twitter: new UntypedFormControl(false, []),
      linkedin: new UntypedFormControl(false, []),
    }, {
      updateOn: 'change',
    });
    // console.log("dialog data = ", data);
  }

  ngOnInit() {
    this.id = this.data.id;
    if(this.data && this.data.type != 'card'){
      this.form.controls['type'].setValue(this.data.type);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    this.form.markAllAsTouched();
    this.create();
  }

  create() {
    if (this.form.valid) {
      this.btn_save_disabled = true;
      this.btn_save_loader = true;

      let data = this.form.value;

      this.dataService.postAsPromise(`admin/news/publish/${this.id}`, data).then(res => {
        if (res && res.data) {
          this.dialogRef.close({ item: res.data });
        } else {
          this.dialogRef.close('bt_save');
        }
        this.btn_save_disabled = false;
        this.btn_save_loader = false;
      }).catch(err => {
        this.btn_save_disabled = false;
        this.btn_save_loader = false;
      });
    } else {
      console.log("Form not valid");
    }
  }
}
